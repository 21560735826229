import React, { useEffect, useState } from 'react'
import { LoadableQuintNavHeader } from '../LoadableComponents'
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'
import ArticleComponent from './ArticleComponent'
import useBreakpoint from '../../customHooks/useBreakpoint'

const tabs = [
  'All',
  'Design',
  // 'Figma',
  'Development',
  'Business',
]

const tabsUri = [
  '/',
  '/design',
  // '/figma',
  '/development',
  '/business',
]

const BlogSectionTemplate = ({ pageContext }) => {
  if (!pageContext) {
    return ''
  }
  const isMobile = useBreakpoint('sm', false)

  const sectionTitle = Object.keys(pageContext)[0]
  const articleArray = pageContext[sectionTitle]
  const recentArticles = articleArray.slice(0, 4)

  const sectionSortedPosts = []

  articleArray.forEach((post) => {
    if (post.node.data.section.includes(sectionTitle)) {
      sectionSortedPosts.push(post)
    }
  })

  const articleData = sectionSortedPosts.map((article) => {
    return article.node
  })

  const url = typeof window !== 'undefined' ? window.location.href : ''
  const splitUri = url.split('/')
  const uri = splitUri[splitUri.length - 1]

  const pageTab = () => {
    if (uri) {
      const splitUri = uri.split('/')
      const page = splitUri[splitUri.length - 1]
      switch (page) {
        case '':
          return 0
        case 'design':
          return 1
        // case 'figma':
        //   return 2
        case 'development':
          return 2
        case 'business':
          return 3
        default:
          return 0
      }
    }
    return 0
  }

  const handleTabSelect = (index) => {
    navigate(`/blog${tabsUri[index]}`)
  }

  const [selectedTab, setSelectedTab] = useState(pageTab)

  useEffect(() => {
    setSelectedTab(pageTab)
  }, [uri])

  // return (
  // <StaticQuery
  //   query={graphql`
  //       query FigmaBlogSection {
  //         prismicFigmaMeetups {
  //           data {
  //             meetups_paragraph
  //             meetups_next_meetup_description
  //             body {
  //               primary {
  //                 date
  //                 location
  //                 organisation_1
  //                 organisation_2
  //                 presenter_1
  //                 presenter_2
  //                 title
  //               }
  //               items {
  //                 image {
  //                   url
  //                 }
  //                 image_title
  //               }
  //             }
  //           }
  //         }
  //         prismicFigmaPresentations {
  //           data {
  //             presentations_paragraph
  //             body {
  //               primary {
  //                 title
  //               }
  //               items {
  //                 meetup_title
  //                 name
  //                 organisation
  //                 role
  //                 link {
  //                   url
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         prismicFigmaGallery {
  //           data {
  //             gallery_paragraph
  //             body {
  //               primary {
  //                 title
  //               }
  //               items {
  //                 image {
  //                   url
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     `}
  //   render={data => {
  //     console.log('data', data)
  return (
    <div
      className='content-container'
      style={{
        minHeight: isMobile ? `calc(100vh - 140px)` : `calc(100vh - 157px)`,
        marginTop: isMobile ? 140 : 157,
        justifyContent: 'flex-start',
        backgroundColor: '#F7F7F7',
      }}
    >
      <LoadableQuintNavHeader
        blog
        tabs={tabs}
        color='#25317B'
        setSelectedTab={handleTabSelect}
        selectedTab={selectedTab}
        backgroundColor='#EFF8FF'
        borderColor='#EFF8FF'
        style={{ marginTop: 85 }}
        key='quint-nav-header-blog-section'
      />
      <div
        style={{
          padding: '86px 0',
          width: '100%',
          maxWidth: 1300,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {sectionTitle && (
            <div
              className='font-main color-primary quint-heading'
              style={
                isMobile
                  ? { fontSize: 18, marginBottom: 18, textAlign: 'left', paddingLeft: 24 }
                  : { fontSize: 24, marginBottom: 24, lineHeight: 1 }
              }
            >
              {sectionTitle}
            </div>
          )}
          {articleData &&
            articleData.map((article, index) => {
              const link = `/blog/${article.data.section
                .toLowerCase()
                .replace(/ /g, '-')}/${article.uid.toLowerCase().replace(/ /g, '-')}`
              return (
                <ArticleComponent
                  size='lg'
                  background={article.data.background}
                  icon={article.data.icon}
                  date={article.data.date}
                  name={article.data.name}
                  tags={article.tags}
                  content={article.data.content}
                  subtitle={article.data.title}
                  link={link}
                  style={isMobile ? { maxWidth: '100%' } : { marginBottom: 48 }}
                  key={index}
                />
              )
            })}
          {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}> */}
          {/*  <div className='font-main quint-supportive' style={{ color: '#E15B33', opacity: 0.3, cursor: 'pointer' }}> */}
          {/*    Newer */}
          {/*  </div> */}
          {/*  <div className='font-main quint-supportive' style={{ color: '#E15B33', cursor: 'pointer' }}> */}
          {/*    Older */}
          {/*  </div> */}
          {/* </div> */}
        </div>
        {!isMobile && (
          <div style={{ width: '100%', maxWidth: 328 }}>
            <div
              className='font-main color-primary quint-heading'
              style={{ textAlign: 'left', marginBottom: 24, fontSize: 18 }}
            >
              Latest articles
            </div>
            <div>
              {recentArticles &&
                recentArticles.map((article, index) => {
                  const recentArticleData = article.node.data
                  return (
                    <Link
                      style={{
                        textAlign: 'left',
                        textDecoration: 'none',
                      }}
                      to={`/blog/${recentArticleData.section
                        .toLowerCase()
                        .replace(/ /g, '-')}/${article.node.uid}`}
                      key={index}
                    >
                      <div
                        className='font-main color-primary quint-body-bold'
                        style={{ marginBottom: 4 }}
                      >
                        {recentArticleData.title}
                      </div>
                      <div
                        className='font-main color-primary quint-text-small'
                        style={{ textDecoration: 'underline', marginBottom: 24 }}
                      >
                        {recentArticleData.name}
                      </div>
                    </Link>
                  )
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
  // }}
  // />)
}

export default BlogSectionTemplate
